import React, { useState } from "react"
import "./Header.css"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
// import Tooltip from "../Tooltip/Tooltip"
import { Popup } from "semantic-ui-react"
import Access from "../access"

function Header() {
  // const [isNavVisible, setNavVisibility] = useState(false)
  // const [isSmallScreen, setIsSmallScreen] = useState(false)

  const data = useStaticQuery(pageQuery)
  const [isOpen, setIsOpen] = useState(false)
  // const [showPassword, setShowPassword] = useState(false)
  // const showPasswordTooltip = () => {
  //   setShowPassword(!showPassword)
  // }
  // const menuItems = data.allContentfulCategoriaDeMenu.edges
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(max-width: 700px)")
  //   mediaQuery.addListener(handleMediaQueryChange)
  //   handleMediaQueryChange(mediaQuery)

  //   return () => {
  //     mediaQuery.removeListener(handleMediaQueryChange)
  //   }
  // }, [])

  // const handleMediaQueryChange = mediaQuery => {
  //   if (mediaQuery.matches) {
  //     setIsSmallScreen(true)
  //   } else {
  //     setIsSmallScreen(false)
  //   }
  // }

  // const toggleNav = () => {
  //   setNavVisibility(!isNavVisible)
  // }
  return (
    <header id="header" className={`header ${isOpen && "menu-mobile-active"}`}>
      <div className="container">
        <div id="logo" className="logo">
          <Link to="/" className="site-logo">
            <Img
              fadeIn={false}
              alt="Artistes Pedagogs"
              style={{ width: 140 }}
              // style={{ margin: 10}}
              fixed={data.contentfulAsset.fixed}
            />
          </Link>
        </div>
        <div
          onClick={() => setIsOpen(true)}
          id="menu-mobile-open"
          className=" menu-mobile-open menu-mobile-toggle"
        ></div>
        <ul id="menu-main" className="menu-main" style={{ marginTop: 10 }}>
          <li
            id="menu-item-3759"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3759"
          >
            <Link to="/projecte">Projecte</Link>
          </li>
          <li
            id="menu-item-3762"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3762"
          >
            <Link to="/tallers/">Tallers</Link>
          </li>
          <li
            id="menu-item-3493"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3493"
          >
            <Link to="/contacte/">Contacte</Link>
          </li>
          <li
            id="menu-item-4037"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4037"
          >
            <Link to="/inscripcions/">
              <div
                className="tagline-link button button-small menu-contrast-button"
                style={{
                  color: "#fff",
                  backgroundColor: "#89ce40",
                  marginTop: -20,
                }}
              >
                Inscripcions
              </div>
            </Link>
          </li>
          <li
            id="menu-item-3995"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3995"
          >
            <Link 
              to="registre"
            >
              <div
                className="tagline-link button button-small menu-contrast-button"
                id="registre"
                style={{ marginTop: -20 }}
              >
                Registra’t
              </div>
            </Link>
          </li>
          <Popup
            style={{ maxWidth: 220 }}
            content={<Access />}
            on="click"
            basic
            trigger={
              <li
                id="menu-item-343423"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3492343"
              >
                <a style={{ cursor: 'pointer' }}>Aula Virtual</a>
              </li>
            }
          />
        </ul>
        <div className="clear"></div>
        <div
          onClick={() => setIsOpen(false)}
          id="menu-mobile-close"
          className="menu-mobile-close menu-mobile-toggle"
        ></div>
        <ul id="menu-mobile" className="menu-mobile">
          <li
            id="menu-item-3759"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link to="/projecte/">
              <span className="menu-link">
                <span className="menu-title">Projecte</span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-3762"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link to="/tallers/">
              <span className="menu-link">
                <span className="menu-title">Tallers</span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-3493"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link to="/contacte/">
              <span className="menu-link">
                <span className="menu-title">Contacte</span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-3494"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link to="/blog/">
              <span className="menu-link">
                <span className="menu-title">Blog</span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-4037"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link to="/inscripcions/">
              <span className="menu-link">
                <span className="menu-title">
                  <div
                    className="tagline-link button button-small menu-contrast-button"
                    style={{ color: "#fff", backgroundColor: "#89ce40" }}
                  >
                    Inscripcions
                  </div>
                </span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-3995"
            className="menu-item menu-item-type-post_type menu-item-object-page"
          >
            <Link
              to="registre"
            >
              <span className="menu-link">
                <span className="menu-title">
                  <div
                    className="tagline-link button button-small menu-contrast-button"
                    id="registre"
                  >
                    Registra’t
                  </div>
                </span>
              </span>
            </Link>
          </li>
          <li
            id="menu-item-343423"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3492343"
          >
            <Link to="/access">Aula Virtual</Link>
          </li>
        </ul>
      </div>
    </header>
  )
  // return (
  //   <header className="Header">
  //     <Link to="/">
  //       <Img
  // style={{ margin: 10}}
  //     fixed={data.contentfulAsset.fixed}
  //   />
  // </Link>
  //   </header>
  // )
}

export default Header

export const pageQuery = graphql`
  query {
    contentfulAsset(title: { eq: "logo-negre" }) {
      fixed(width: 145, height: 60) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    # contentfulAsset (title: { eq: "logo-negre" }){
    #   fluid (maxWidth: 40) {
    #     ...GatsbyContentfulFluid
    #   }
    # }
  }
`
