import React from "react"
import { Link } from "gatsby"

const sections = [
  {
    id: 'el-project',
    label: 'El projecte',
    link: '/projecte'
  },
  {
    id: 'tallers',
    label: 'Tallers',
    link: '/contacte'
  },
  {
    id: 'contacte',
    label: 'Contacte',
    link: '/contacte'
  },
  {
    id: 'avis-legal',
    label: 'Avís Legal',
    link: '/avis-legal'
  },
  {
    id: 'treballa-amb-nosaltres',
    label: 'Treballa amb nosaltres',
    link: '/treballa-amb-nosaltres'
  },
  {
    id: 'blog',
    label: 'Blog',
    link: '/blog'
  },
]
const Footer = () => (
  <footer id="footer" className="footer secondary-color-bg dark">
    <div className="container">
      <div id="footermenu" className="footermenu">
        <div className="menu-inici-container">
          <ul id="menu-inici" className="menu-footer">
            {sections.map( section => (
              <li
                key={section.id}
                id={section.id}
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children has_children has-children"
              >
                <Link to={section.link}>
                  <span className="menu-link">
                    <span className="menu-title">{section.label}</span>
                  </span>
                </Link>
              </li>
            ))}
            <li
              id="menu-item-4037"
              className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-4027 current_page_item"
            >
              <Link to="/inscripcions">
                <span className="menu-link">
                  <span className="menu-title">
                    <div
                      className="tagline-link button button-small menu-contrast-button"
                      id="inscripcions-obertes"
                    >
                      Inscripcions
                    </div>
                  </span>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-content">© Artistes Pedagogs 2019.</div>
    </div>
  </footer>
)

export default Footer